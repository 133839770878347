import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ease } from '../../utils/easing';

const Overlay = ({ active, onClick, style }) => (
  <StyledOverlay
    key="overlay"
    onClick={onClick}
    initial={{ opacity: 0 }}
    animate={{ opacity: active ? 0.6 : 0 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.6, ease: ease.outSmooth }}
    style={style}
  />
);

const StyledOverlay = styled(motion.div)`
  position: fixed;
  background: var(--black);
  opacity: 0.6;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -1;
`;

Overlay.propTypes = {
  active: PropTypes.bool,
};

export default Overlay;
