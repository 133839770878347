import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Nav from '../atoms/nav';
import { media } from '../../utils/media-queries';

const Header = ({ path }) => (
  <StyledHeader>
    <h1>
      <Link to="/">
        Kreuzberger <br className="mobile" /> Kind
      </Link>
    </h1>
    <Nav path={path} />
  </StyledHeader>
);

const StyledHeader = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  padding: var(--sp-1-3);
  width: 100%;
  justify-content: space-between;
  z-index: 10;

  h1 {
    text-transform: uppercase;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }
  }

  @media ${media.M} {
    .mobile {
      display: none;
    }
  }
`;

Header.propTypes = {
  path: PropTypes.string,
};

export default Header;
