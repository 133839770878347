import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalStyles from '../../styles/globalStyles';
import Normalize from '../../styles/normalize';
import Variables from '../../styles/variables';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
import { ease } from '../../utils/easing';
import LayoutProvider from './layoutProvider';
import IntroAnim from '../molecules/introAnim';

const Layout = ({ children, location }) => {
  const [hideIntro, setHideIntro] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHideIntro(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <LayoutProvider>
      {/**
       * Styles
       */}
      <Normalize />
      <GlobalStyles />
      <Variables />
      {/**
       * Content
       */}
      {!hideIntro && <IntroAnim />}
      <Header path={location && location.pathname} />
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.main
          key={location && `key${location.pathname}`}
          initial={{ opacity: 0.4 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.4 }}
          transition={{ duration: 0.3, ease: ease.inOutSmooth }}
        >
          {children}
        </motion.main>
        {(location.pathname.includes('about') ||
          location.pathname.includes('pix') ||
          location.pathname === '/') && (
          <Footer path={location && location.pathname} />
        )}
      </AnimatePresence>
    </LayoutProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default Layout;
