import { createGlobalStyle } from 'styled-components';

const Variables = createGlobalStyle`

  :root {
    /* Colors */
    --black: #111111;
    --white: #ffffff;

    /* Spacing */
    --sp-0: 0.5rem;
    --sp-1: 1rem;
    --sp-2: 2rem;
    --sp-3: 3rem;
    --sp-5: 5rem;
    --sp-8: 8rem;
    --sp-13: 13rem;
    --sp-21: 21rem;
    --sp-34: 34rem;

    /* Variable Spacing */
    /* Clamp Fallback */ --sp-1-3: var(--sp-2);
    --sp-1-3: clamp(var(--sp-1), 3vw, var(--sp-3));
    /* Clamp Fallback */ --sp-3-5: calc(var(--sp-5) - var(--sp-1));
    --sp-3-5: clamp(var(--sp-3), 3vw, var(--sp-5));
    /* Clamp Fallback */ --sp-5-8: calc(var(--sp-8) - var(--sp-2));
    --sp-5-8: clamp(var(--sp-5), 4vw, var(--sp-8));
    /* Clamp Fallback */ --sp-8-13: calc(var(--sp-13) - var(--sp-3));
    --sp-8-13: clamp(var(--sp-8), 5vw, var(--sp-13));
    /* Clamp Fallback */ --sp-8-13: calc(var(--sp-21) - var(--sp-5));
    --sp-13-21: clamp(var(--sp-13), 6vw, var(--sp-21));
    /* Clamp Fallback */ --sp-8-13: calc(var(--sp-34) - var(--sp-8));
    --sp-21-34: clamp(var(--sp-21), 10vw, var(--sp-34));

    /* Fonts */
    --f-sans: 'Soehne', sans-serif;

    /* Font Sizes */
    /* Clamp Fallback */ --fs-hl: calc(32px + (36 - 32) * (100vw - 375px) / (2560 - 375));
    --fs-hl: clamp(3.2rem, 5vw, 3.6rem);
    /* Clamp Fallback */ --fs-copy: calc(16px + (18 - 16) * (100vw - 375px) / (2560 - 375));
    --fs-copy: clamp(1.6rem, 3vw, 1.8rem);
    /* Clamp Fallback */ --fs-small: calc(10px + (12 - 10) * (100vw - 375px) / (2560 - 375));
    --fs-small: clamp(1rem, 3vw, 1.2rem);

    /* Font Settings */
    --ls: 0.01rem;
    --lh: 1.2;
    --lh-hl: 1.1;
    --lh-copy: 1;

    /* Border */
    --brd-width: 1px;
    --brd: var(--border-width) solid var(--text-color);

    /* Layout */
    --g-cols: 2;
    --g-gap: var(--sp-3);

    /* Misc */
  }
`;

export default Variables;
