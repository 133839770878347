import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../utils/media-queries';

const Footer = ({ path }) => {
  const { mail, about } = useStaticQuery(graphql`
    query Mail {
      mail: allSanityAbout {
        nodes {
          mail
        }
      }
      about: allSanityAbout {
        nodes {
          social {
            title
            url
          }
        }
      }
    }
  `);

  return (
    <StyledFooter
      className={path.includes('about') || path.includes('pix') ? '' : 'fix'}
    >
      <Link to="/legal">Privacy Policy & Disclaimer</Link>
      <a href={`mailto:${mail.nodes[0].mail}`} className="mail">
        {mail.nodes[0].mail}
      </a>
      {about.nodes[0].social.map((item) => (
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          className="social"
        >
          {item.title}
        </a>
      ))}
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding: var(--sp-1-3);
  font-size: var(--fs-small);
  display: flex;
  justify-content: space-between;
  width: 100%;

  .mail,
  .social {
    padding-left: var(--sp-1-3);
  }

  a {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }
  }

  .social {
    display: none;
  }

  &.fix {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }

  @media ${media.M} {
    justify-content: flex-start;

    .social {
      display: block;
    }
  }
`;

Footer.propTypes = {
  path: PropTypes.string,
};

export default Footer;
