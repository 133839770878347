import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Herz from '../../assets/svgs/herz-logo.svg';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ease } from '../../utils/easing';

const IntroAnim = () => {
  const [triggerAnim, setTriggerAnim] = useState(false);
  const size = useWindowSize();
  const [height, setHeight] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTriggerAnim(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setHeight(size.height);
  }, [size]);

  return (
    <StyledIntroAnim
      style={{ height }}
      animate={{ opacity: triggerAnim ? 0 : 1 }}
      transition={{ duration: 0.6, ease: ease.outSnap }}
    >
      <motion.div
        className="svg"
        animate={{ scale: triggerAnim ? 3 : 1 }}
        transition={{ duration: 0.8, ease: ease.outSnap }}
      >
        <Herz />
      </motion.div>
    </StyledIntroAnim>
  );
};

const StyledIntroAnim = styled(motion.div)`
  position: fixed;
  background: var(--black);
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 1s;

  svg {
    transform: scale(1.2);

    g {
      fill: var(--white);
    }
  }
`;

export default IntroAnim;
