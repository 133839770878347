import { motion } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import { ease } from '../../utils/easing';
import { media } from '../../utils/media-queries';

const IndexOverlay = ({ active, onClick }) => {
  const { index } = useStaticQuery(graphql`
    query Index {
      index: allSanityProjectsPage {
        nodes {
          _rawProjects(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `);

  const { _rawProjects } = index.nodes[0];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.03,
        duration: 1,
        ease: ease.outSmooth,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const ref = useRef();

  useEffect(() => {
    if (active) {
      disableBodyScroll(ref.current);
    } else if (!active) {
      enableBodyScroll();
    }

    return () => clearAllBodyScrollLocks();
  }, [active]);

  return (
    <StyledIndexOverlay
      key="indexoverlay"
      variants={container}
      initial="hidden"
      animate={active ? 'show' : 'hidden'}
      exit="hidden"
      className="index-overlay"
      ref={ref}
      onClick={onClick}
    >
      {_rawProjects
        .sort((a, b) => (a.title + a.subTitle > b.title + b.subTitle ? 1 : -1))
        .map((project, i) => (
          <Link key={i} to={`/${project.slug.current}`}>
            <motion.li className="index" variants={item}>
              {project.title} <br /> {project.subTitle}
            </motion.li>
          </Link>
        ))}
    </StyledIndexOverlay>
  );
};

const StyledIndexOverlay = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: var(--sp-3);
  padding-bottom: var(--sp-21);
  height: 100vh;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .index {
    padding-left: 0;
    padding-right: var(--sp-1-3);
    padding-bottom: var(--sp-1);
  }

  a {
    transition: 0.3s;
  }

  &:hover a:not(:hover) {
    opacity: 0.3;
  }

  @media ${media.M} {
    padding-bottom: var(--sp-13);
  }
`;

IndexOverlay.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IndexOverlay;
