/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import IndexOverlay from '../molecules/indexOverlay';
import Overlay from './overlay';
import { media } from '../../utils/media-queries';

const Nav = ({ path }) => {
  const [indexOpen, setIndexOpen] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <StyledNav>
      <ul className="nav">
        <li>
          <button
            type="button"
            className={`nav-item uc ${
              (path.includes('about') || path.includes('pix')) && !hover
                ? 'not-active'
                : ''
            }`}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setIndexOpen(!indexOpen)}
            style={{ opacity: indexOpen && 1 }}
          >
            A <span /> Z
          </button>
          <AnimatePresence>
            {indexOpen && (
              <>
                <Overlay
                  active={indexOpen}
                  onClick={() => setIndexOpen(false)}
                />
                <IndexOverlay
                  active={indexOpen}
                  onClick={() => setIndexOpen(false)}
                />
              </>
            )}
          </AnimatePresence>
        </li>
        <li
          className={`nav-item uc ${
            path.includes('pix') && !hover ? 'not-active' : ''
          }`}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setIndexOpen(false)}
        >
          <Link to="/about" activeClassName="active">
            About
          </Link>
        </li>
        <li
          className={`nav-item uc ${
            path.includes('about') && !hover ? 'not-active' : ''
          }`}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setIndexOpen(false)}
        >
          <Link to="/pix" activeClassName="active">
            Pix
          </Link>
        </li>
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  ul {
    display: flex;

    li {
      padding-left: var(--sp-3);

      span {
        background: var(--white);
        display: inline-block;
        height: 1.5px;
        width: 15px;
        margin-right: 4px;
        margin-left: 3px;
        margin-bottom: 4px;
      }
    }
  }

  .not-active {
    opacity: 0.3;
  }

  .nav-item {
    transition: 0.3s;
  }

  .uc {
    text-transform: uppercase;
  }

  .nav:hover .nav-item:not(:hover) {
    opacity: 0.3;
  }

  @media ${media.M} {
    ul {
      li {
        padding-left: var(--sp-5-8);

        span {
          margin-right: 5px;
          margin-left: 4px;
          height: 1.7px;
          width: 22px;
        }
      }
    }
  }
`;

Nav.propTypes = {
  path: PropTypes.string,
};

export default Nav;
