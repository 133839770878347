import { createGlobalStyle } from 'styled-components';
import Soehne from '../assets/fonts/soehne-web-buch.woff';
import Soehne2 from '../assets/fonts/soehne-web-buch.woff2';

const GlobalStyles = createGlobalStyle`

  html {
    font-family: var(--f-sans);
    color: var(--white);
    background: var(--black);
    font-size: 10px;
  }

  body {
    font-size: var(--fs-copy);
    line-height: var(--lh);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
  }

  /* Typography */

  @font-face {
    font-family: Soehne;
    src: url(${Soehne});
  }

  @font-face {
    font-family: Soehne;
    src: url(${Soehne2});
  }

  .large {
    font-size: var(--fs-hl);
    line-height: var(--lh-hl);
  }

  /* Images */

  .gatsby-image-wrapper, video {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }

  video {
    object-fit: cover;
    width: 100%;
  }

  /* Scrollbar */

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

`;

export default GlobalStyles;
